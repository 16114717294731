import { createError, defineNuxtRouteMiddleware } from 'nuxt/app';

import { PAGE_NOT_FOUND_404 } from '@/constants/error.const';

export default defineNuxtRouteMiddleware(() => {
  throw createError({
    statusCode: PAGE_NOT_FOUND_404,
    message: 'Page not found'
  });
});
